import { graphql } from 'gatsby'
import React from 'react'
import get from 'lodash/get'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import SEO from '../components/seo'
import Layout from 'components/layout'

import '../scss/style.scss'

const HeaderLineIndex = () => {
  return (
    <div class="header bg-white">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="display-4 pagetitle">Relationships</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const PictureLine = data => {
  return (
    <div class="header bg-white">
      <div class="container">
        <div class="row my-5">
          <div class="col-md-6">
            <div class="devices pl-md-4 pt-5 pt-md-0 pr-3">
              <Img
                fluid={data.imageOne.childImageSharp.fluid}
                alt="Relationship page image"
                className="rounded shadow-lg"
              />
            </div>
          </div>
          <div class="col-md-6 my-5">
            <p>
              This is not a skill that you are born with, it is learnt from
              parents, caregivers, and society. Relationship coaching guides
              improvement in all categories of relationships, be it your
              partner, your parents, siblings, children, employees, bosses,
              co-workers or friends. You are guided in learning the skills
              necessary for building healthy relationships and replacing the
              unhealthy patterns.{' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const FamilyDescription = () => {
  return (
    <div class="container pl-5 my-5">
      <p class="text-left pagequote">Family</p>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Family Coaching help you relate to your family members and what they
            mean to you.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Family Coaching can help you improve decision making, deepen
            learning and enhance relationship qualities.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaching helps improve your sibling, marital, and parent-child
            relationships through effective communication skills.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaching guides you in dealing with conflict resolution of family
            members of different views and opinions which creates resentments
            and arguments.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Improve mutual respect by being aware of behaviour and patterns
            which impact on values and choices.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            The strategies will help you to be more honest, committed and
            confident in creating a fulfilling relationship of unconditional
            love and support.
          </span>
        </div>
      </div>
    </div>
  )
}

const PreMaritalDescription = () => {
  return (
    <div class="container pl-5 my-5">
      <p class="text-left pagequote">Pre-marital</p>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaching will assist you as a couple in understanding the economic
            partnership involved, whereby you will want to buy a house, a car or
            other household articles together.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaching will highlight the delicate balance to be maintained at all
            times in any kind of relationship.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaching skills will guide you as a couple on the importance of
            being patient and understanding.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaching guides you on the importance of trust, respect,
            communication, compatibility and love in your relationship.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaching guides you in dealing with values and beliefs in a mutual
            manner.
          </span>
        </div>
      </div>
    </div>
  )
}

const MarriageDescription = () => {
  return (
    <div class="container pl-5 my-5">
      <p class="text-left pagequote">Marriage</p>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>A marriage coach can help untangle knots in a marriage.</span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Through coaching, relationship issues can be clarified whereby a
            coach can separate what you can control from what you cannot.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaching highlights awareness, in terms of fear, resentment and
            avoidance.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Marriage Coaches can help couples develop strong, healthy and
            vibrant relationships.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaches, through their skillset can guide you in achieving your
            goals
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Marriage Coaching can guide couples on coping with challenges,
            improve communication and understanding, reconnect, and learn
            forgiveness.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaching can guide you in breaking patterns that you are stuck in
            and find out what the real problem is.
          </span>
        </div>
      </div>
    </div>
  )
}

export default ({ data, location }) => (
  <Layout location={location} crumbLabel="Relationships">
    <SEO site={get(data, 'site.meta')} />
    {HeaderLineIndex()}
    {PictureLine(data)}
    {FamilyDescription()}
    {PreMaritalDescription()}
    {MarriageDescription()}
  </Layout>
)

export const query = graphql`
  query {
    imageOne: file(relativePath: { eq: "heart-68196_1920.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
